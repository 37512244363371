<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <SearchBar></SearchBar>

    <div class="flex md:flex-row mx-2 flex-wrap">
      <div class="w-full md:w-1/2 bg-white p-4 text-center text-gray-200">
        <h2
          class="text-2xl text-left text-black tracking-wide font-semibold leading-loose mb-2"
        >
          {{ currentPackage.type ? currentPackage.type.name : "" }} at
          {{ currentPackage.property ? currentPackage.property.name : "" }}
          {{ currentPackage.roomType ? currentPackage.roomType.name : "" }}
          <span class="text-sm">
            <i class="text-yellow-400 pr-1 fas fa-star"></i>
            <i class="text-yellow-400 pr-1 fas fa-star"></i>
            <i class="text-yellow-400 pr-1 fas fa-star"></i>
            <i class="text-yellow-400 pr-1 fas fa-star"></i>
            <i class="text-yellow-400 pr-1 fas fa-star"></i>
            <i class="text-black pl-1 fas fa-map-marker-alt"></i>
          </span>
        </h2>

        <!--     
        <splide :options="primaryOptions" ref="primary">
          <splide-slide v-for="slide in property.slides" :key="slide">
            <img :src="`${$customConfig.api_host}/uploads/${slide}`" alt="" />
          </splide-slide>
        </splide>

        <splide :options="secondaryOptions" ref="secondary">
          <splide-slide v-for="slide in property.slides" :key="slide">
            <img
              :src="`${$customConfig.api_host}/uploads/${slide}`"
              alt="slide.alt"
            />
          </splide-slide>
        </splide> -->

        <splide
          :slides="
            currentPackage.property ? currentPackage.property.slides : []
          "
          :options="primaryOptions"
          ref="primary"
        >
          <splide-slide class="rounded" style="width: 100%">
            <img
              :src="`${$customConfig.api_host}/uploads/${currentPackage.featuredImage}`"
              alt="slide.alt"
            />
          </splide-slide>
        </splide>

        <splide
          style="margin-top: 0.1rem"
          :slides="
            currentPackage.property ? currentPackage.property.slides : []
          "
          :options="secondaryOptions"
          ref="secondary"
        >
          <splide-slide>
            <img
              :src="`${$customConfig.api_host}/uploads/${currentPackage.featuredImage}`"
              alt="slide.alt"
            />
          </splide-slide>
        </splide>

        <div
          class="flex md:flex-row bg-green-100 rounded mt-2 p-4 inline-block items-center justify-center py-5 text-black"
        >
          <h4 class="font-semibold pr-4">BOOK BEFORE</h4>
          <i class="text-black pl-1 pr-1 fas fa-clock"></i>
          <h4 class="font-semibold pr-4">
            {{ currentPackage.bookBefore }}
          </h4>
        </div>

        <div class="w-full lg:flex mb-5">
          <div
            class="w-full rounded mt-2 text-black text-left border-r border-b border-l border-grey-light lg:border-l-1 lg:border-t lg:border-grey-light bg-white p-4 flex flex-col justify-between leading-normal"
          >
            <span
              v-html="
                currentPackage.property
                  ? currentPackage.property.description
                  : ''
              "
            >
            </span>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 bg-gray-100 p-4 text-center text-gray-700">
        <h2
          class="text-2xl text-right text-black tracking-wide font-semibold leading-loose mb-2"
        >
          Package Details
        </h2>

        <div
          class="flex md:flex-row bg-yellow-300 rounded mt-2 p-4 inline-block items-center justify-left py-5 text-black"
        >
          <h4 class="font-semibold pr-4">STAY BETWEEN</h4>
          <i class="text-black pl-1 pr-1 fas fa-calendar-alt"></i>
          <h4 class="font-semibold pr-4">
            {{ currentPackage.stayBetweenStart }}
          </h4>
          <i class="text-black pl-1 pr-1 fas fa-calendar-alt"></i>
          <h4 class="font-semibold pr-4">
            {{ currentPackage.stayBetweenEnd }}
          </h4>

          <button
            @click="bookRoom(currentPackage._id)"
            type="button"
            class="border border-black text-white rounded-md px-8 py-2 mr-2 transition duration-500 ease select-none bg-black hover:bg-gray-800 focus:outline-none focus:shadow-outline text-right"
          >
            Book
          </button>
        </div>

        <div class="w-full lg:flex mb-5">
          <div
            class="w-full rounded mt-2 border-r border-b border-l border-grey-light lg:border-l-1 lg:border-t lg:border-grey-light bg-white p-4 flex flex-col justify-between leading-normal"
          >
            <div class="mb-8">
              <p
                class="text-sm font-bold text-grey-dark flex items-left border-b border-gray-100 pb-2"
              >
                Package Inclusions / Terms & Conditions
              </p>
              <div class="text-black font-normal text-m mb-2 mt-2 text-left">
                <!-- 
Package Terms Write-up goes here
 -->
              </div>
            </div>
            <div class="flex text-left">
              <div class="text-sm">
                <p class="text-black leading-none"></p>
                <p class="text-grey-dark"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-6xl mx-auto mt-6">
      <div class="flex flex-wrap">
        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img
                  src="https://static.thenounproject.com/png/3309285-200.png"
                  width="75px"
                />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Luxury Maldives Experience
              </h2>
              <p class="text-sm">
                We can guarentee peace of mind when booking with us
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img
                  src="https://static.thenounproject.com/png/3533484-200.png"
                  width="75px"
                />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Travel Insurance Protection
              </h2>
              <p class="text-sm">
                Our holiday packages are financially protected by Travel
                Insurance
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img
                  src="https://static.thenounproject.com/png/3188775-200.png"
                  width="75px"
                />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Flexible Payments
              </h2>
              <p class="text-sm">
                Spread the cost of your luxury holiday with monthly payments
                with easy online payments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="clear: both; display: block; height: 0px"></div>

    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";
import SearchBar from "@/components/others/SearchBar";

import { createSlides } from "../others/slides.js";

export default {
  name: "PackagesPage",
  data() {
    return {
      property: {
        slides: [],
      },
      currentPackage: "",
      properties: [],
      roomTypes: [],
      packageTypes: [],
      totalPages: "",

      currentPage: this.$route.query.page * 1 || 1,
      primaryOptions: {
        type: "loop",
        perPage: 1,
        perMove: 1,
        autoWidth: false,
        fixedHeight: 520,
        gap: "1rem",
        cover: true,
        pagination: false,
      },
      roomTypesSlides: {
        type: "loop",
        perPage: 1,
        perMove: 1,
        fixedWidth: 380,
        fixedHeight: 220,
        focus: "center",
        cover: true,
        gap: "0rem",
        pagination: false,
      },
      secondaryOptions: {
        type: "loop",
        rewind: true,
        gap: "0.1rem",
        pagination: false,
        cover: true,
        focus: "center",
        fixedWidth: 120,
        fixedHeight: 80,
        isNavigation: false,
        updateOnMove: true,
      },
      slides: createSlides(),
      count: 0,
    };
  },
  mounted() {
    this.getPackage();
  },
  methods: {
    bookRoom(id) {
      this.$router.push(`/package-booking?package=${id}`);
    },
    async getPackage() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/packages/${this.$route.params.id}`
        );
        response.data.package.initial = true;
        response.data.package.bookBefore = moment(
          response.data.package.bookBefore
        ).format("YYYY-MM-DD");
        response.data.package.stayBetweenStart = moment(
          response.data.package.stayBetweenStart
        ).format("YYYY-MM-DD");
        response.data.package.stayBetweenEnd = moment(
          response.data.package.stayBetweenEnd
        ).format("YYYY-MM-DD");
        this.currentPackage = response.data.package;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async getProperties() {
    try {
      const response = await axios.get(
        `${this.$customConfig.api_host}/api/v1/properties`
      );
      this.properties = response.data.properties;
      this.totalPages = response.data.totalPages;
      console.log("hello");
    } catch (e) {
      console.log(e);
    }
  },
  components: {
    HeaderLayout,
    FooterLayout,
    SearchBar,
  },
};
</script>

<style scoped>
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
           WDM
 –––––––––––––––––––––––––––––––––––––––––––––––––– */
</style>
